<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">Villa Booking</h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Villa Booking</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section>
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="row mb-1">
                  <div class="d-flex col-12">
                    <div class="float-start col-6"></div>
                    <div class="float-end col-6">
                      <router-link
                        :to="{ name: 'booking' }"
                        class="btn btn-gradient-primary float-end"
                      >
                        Back
                      </router-link>
                    </div>
                  </div>
                </div>
                <section>
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <input type="hidden" name="id" />
                        <div class="flex-grow-1">
                          <div class="p-1 sectionBlock" id="section-block">
                            <div class="row">
                              <div class="col-sm-12 col-lg-6">
                                <div class="mb-1">
                                  <label
                                    class="form-label"
                                    for="basic-addon-name"
                                    >Check in - Check out date</label
                                  >
                                  <Datepicker
                                    type="date"
                                    @closed="dateChange()"
                                    @cleared="resetForm()"
                                    :min="
                                      new Date().toISOString().substr(0, 10)
                                    "
                                    v-model="form.rangeDate"
                                    format="dd-MM-yyyy"
                                    value-format="dd-MM-yyyy"
                                    :min-date="new Date()"
                                    modelAuto
                                    range
                                    multiCalendars
                                    multiCalendarsSolo
                                    placeholder="Check in - Check out date"
                                  />
                                </div>
                                <div
                                  class="bk_title_info_info_main_row"
                                  v-if="form.rangeDate"
                                >
                                  <div
                                    v-if="villaList.length"
                                    class="villa_card_wrapper"
                                  >
                                    <div
                                      class="bk_title_info_info"
                                      v-for="(villa, index) in villaList"
                                      :key="villa.villaTypeId"
                                    >
                                      <div
                                        class="bk_title_info_info_inr"
                                        @click="villaClick(villa.villaTypeId)"
                                      >
                                        <div
                                          class="bk_title_info cursor_pointer"
                                          :class="
                                            clickVillaId.find(
                                              (c) => c == villa.villaTypeId
                                            )
                                              ? `activeClick`
                                              : ``
                                          "
                                        >
                                          <div class="bk_title_img">
                                            <a
                                              :href="villa.villa_images[0]"
                                              data-lightbox="models"
                                              data-title="STRUCTURE-2"
                                            >
                                              <img
                                                :src="villa.villa_images[0]"
                                              />
                                            </a>
                                            <!-- <span>View Vila</span> -->
                                          </div>
                                          <div class="bk_content">
                                            <div class="vila_title">
                                              {{
                                                villa.villa_name.toLowerCase()
                                              }}
                                            </div>
                                            <div
                                              class="vila_title_info"
                                              :class="
                                                clickVillaId.find(
                                                  (c) => c == villa.villaTypeId
                                                )
                                                  ? `activeClick`
                                                  : ``
                                              "
                                            >
                                              <span> Max Quantity </span>
                                              <svg
                                                width="11"
                                                height="11"
                                                viewBox="0 0 11 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                                                  fill="#153936"
                                                />
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                                                  fill="#153936"
                                                />
                                              </svg>
                                              <span
                                                >1 &nbsp;&nbsp; x &nbsp;
                                              </span>
                                              <svg
                                                width="11"
                                                height="11"
                                                viewBox="0 0 11 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                                                  fill="#153936"
                                                />
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                                                  fill="#153936"
                                                />
                                              </svg>
                                              <span class="font_bold">{{
                                                villa.allow_adult
                                              }}</span>
                                            </div>
                                            <div
                                              class="vila_title_info"
                                              :class="
                                                clickVillaId.find(
                                                  (c) => c == villa.villaTypeId
                                                )
                                                  ? `activeClick`
                                                  : ``
                                              "
                                            >
                                              <span> Price </span>&nbsp;:&nbsp;
                                              <span class="font_bold"
                                                ><span class="rupee">₹</span
                                                >{{
                                                  villa.price
                                                    .toString()
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )
                                                }}.00</span
                                              >
                                            </div>
                                            <div
                                              class="vila_title_info"
                                              :class="
                                                clickVillaId.find(
                                                  (c) => c == villa.villaTypeId
                                                )
                                                  ? `activeClick`
                                                  : ``
                                              "
                                            >
                                              <span> Weekend Price </span
                                              >&nbsp;:&nbsp;
                                              <span class="font_bold"
                                                ><span class="rupee">₹</span
                                                >{{
                                                  villa.weekend_price
                                                    .toString()
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )
                                                }}.00</span
                                              >
                                            </div>
                                            <div
                                              class="vila_title_info"
                                              :class="
                                                clickVillaId.find(
                                                  (c) => c == villa.villaTypeId
                                                )
                                                  ? `activeClick`
                                                  : ``
                                              "
                                            >
                                              <span> Max Adult Limit</span
                                              >&nbsp;:&nbsp;
                                              <span class="font_bold">{{
                                                villa.max_adult_limit
                                              }}</span>
                                            </div>
                                            <div
                                              class="vila_title_info"
                                              :class="
                                                clickVillaId.find(
                                                  (c) => c == villa.villaTypeId
                                                )
                                                  ? `activeClick`
                                                  : ``
                                              "
                                            >
                                              <span> Extra Person</span
                                              >&nbsp;:&nbsp;
                                              <span class="font_bold"
                                                ><span class="rupee">₹</span
                                                >{{
                                                  parseFloat(
                                                    villa.extra_per_person_charge ||
                                                      0
                                                  )
                                                    .toString()
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )
                                                }}.00</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="vialla_qty_main_inner"
                                          v-if="!villa.message"
                                        >
                                          <div class="vialla_qty_main">
                                            <!-- <span>Villa Qty</span> -->
                                            <div
                                              class="main_class_inner_sd select_image"
                                            >
                                              <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M8.11076 2.83302C7.4653 2.30311 6.53535 2.30311 5.88989 2.83302L2.76003 5.4026C2.49011 5.6242 2.33366 5.95507 2.33366 6.3043V10.5C2.33366 11.1443 2.85599 11.6666 3.50033 11.6666H10.5003C11.1447 11.6666 11.667 11.1443 11.667 10.5V6.3043C11.667 5.95507 11.5105 5.6242 11.2406 5.4026L8.11076 2.83302ZM5.1496 1.93131C6.22536 1.04812 7.77529 1.04812 8.85106 1.93132L11.9809 4.50089C12.5208 4.94409 12.8337 5.60583 12.8337 6.3043V10.5C12.8337 11.7886 11.789 12.8333 10.5003 12.8333H3.50033C2.21166 12.8333 1.16699 11.7886 1.16699 10.5V6.3043C1.16699 5.60583 1.4799 4.94409 2.01974 4.50089L5.1496 1.93131Z"
                                                  fill="#153936"
                                                />
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M4.66699 9.91671C4.66699 8.62804 5.71166 7.58337 7.00033 7.58337C8.28899 7.58337 9.33366 8.62804 9.33366 9.91671V12.25H8.16699V9.91671C8.16699 9.27238 7.64466 8.75004 7.00033 8.75004C6.35599 8.75004 5.83366 9.27238 5.83366 9.91671V12.25H4.66699V9.91671Z"
                                                  fill="#153936"
                                                />
                                              </svg>
                                              <select
                                                :id="`qty_${villa.villaTypeId}`"
                                                name="villaQty"
                                                v-model="
                                                  villa_type[villa.pack_type]
                                                    .qty
                                                "
                                                @change="
                                                  onChangeVillaQty(
                                                    villa.villaTypeId,
                                                    villa_type[villa.pack_type]
                                                      .qty,
                                                    villa_type[villa.pack_type]
                                                      .person
                                                  )
                                                "
                                              >
                                                <option
                                                  :value="0"
                                                  disabled
                                                  hidden
                                                >
                                                  Qty
                                                </option>
                                                <option
                                                  v-for="(
                                                    vQty, vIndex
                                                  ) in villa.villa_quantity"
                                                  :key="
                                                    villa.villaTypeId +
                                                    '_' +
                                                    vIndex
                                                  "
                                                  :value="
                                                    villa.villaTypeId +
                                                    '_' +
                                                    vQty
                                                  "
                                                >
                                                  {{ vQty }}
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                          <div class="vialla_qty_main">
                                            <!-- <span>Total Prax</span> -->
                                            <div
                                              class="main_class_inner_sd select_image"
                                            >
                                              <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                                                  fill="#153936"
                                                />
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                                                  fill="#153936"
                                                />
                                              </svg>
                                              <select
                                                :id="`person_${villa.villaTypeId}`"
                                                name="villaPerson"
                                                v-model="
                                                  villa_type[villa.pack_type]
                                                    .person
                                                "
                                                :disabled="
                                                  villa_type[villa.pack_type]
                                                    .qty <= 0
                                                "
                                                @change="
                                                  onChangeVillaQty(
                                                    villa.villaTypeId,
                                                    villa_type[villa.pack_type]
                                                      .qty,
                                                    villa_type[villa.pack_type]
                                                      .person
                                                  )
                                                "
                                              >
                                                <option
                                                  :value="0"
                                                  disabled
                                                  hidden
                                                >
                                                  Person
                                                </option>
                                                <option
                                                  v-for="(
                                                    pQty, pIndex
                                                  ) in villa_booking[index]
                                                    .quantity *
                                                  villa.max_adult_limit"
                                                  :key="
                                                    villa.villaTypeId +
                                                    '_' +
                                                    pIndex
                                                  "
                                                  :value="
                                                    villa.villaTypeId +
                                                    '_' +
                                                    pQty
                                                  "
                                                >
                                                  {{ pQty }}
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="vialla_qty_main_inner text-danger"
                                          v-else
                                        >
                                          {{ villa.message }}
                                        </div>
                                        <p
                                          class="reverse_error"
                                          v-if="
                                            reserve_error[villa.pack_type].error
                                          "
                                        >
                                          {{
                                            reserve_error[villa.pack_type].error
                                          }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-else class="bk_title noslotavailable">
                                    No Slots Available!
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-lg-6">
                                <div class="mb-1">
                                  <div class="row">
                                    <div class="col-4">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Villa Amount</label
                                      >
                                      <input
                                        class="form-control"
                                        :value="
                                          showCalc.villa_price >= 1
                                            ? showCalc.villa_price
                                            : ''
                                        "
                                        placeholder="Amount"
                                        readonly
                                      />
                                    </div>
                                    <div class="col-4">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Extra Charges</label
                                      >
                                      <input
                                        class="form-control"
                                        :value="
                                          showCalc.extra_person_charge > 1
                                            ? showCalc.extra_person_charge
                                            : ''
                                        "
                                        placeholder="Extra Charges"
                                        readonly
                                      />
                                    </div>
                                    <div class="col-4">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Festival Charges</label
                                      >
                                      <input
                                        class="form-control"
                                        :value="
                                          showCalc.festival_charge >= 1
                                            ? showCalc.festival_charge
                                            : ''
                                        "
                                        placeholder="Festival Charges"
                                        readonly
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="mb-1">
                                  <div class="row">
                                    <div class="col-6">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Discount Amount</label
                                      >
                                      <!-- <input
                                        readonly
                                        class="form-control"
                                        :value="
                                          showCalc.coupon_amount >= 1
                                            ? showCalc.coupon_amount
                                            : ''
                                        "
                                        id="coupon_amount"
                                        placeholder="Discount Amount"
                                      /> -->
                                      <input
                                        class="form-control"
                                        v-model="bookingResult.coupon_amount"
                                        id="coupon_amount"
                                        placeholder="Discount Amount"
                                      />
                                    </div>
                                    <div class="col-6">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >GST Amount</label
                                      >
                                      <input
                                        class="form-control"
                                        :value="
                                          showCalc.gst_tax >= 1
                                            ? parseFloat(
                                                showCalc.gst_tax
                                              ).toFixed(2)
                                            : ''
                                        "
                                        placeholder="GST Amount"
                                        readonly
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="mb-1">
                                  <label
                                    class="form-label"
                                    for="basic-addon-name"
                                    >Final Amount</label
                                  >
                                  <input
                                    class="form-control"
                                    :value="
                                      showCalc.final_total >= 1
                                        ? showCalc.final_total
                                        : ''
                                    "
                                    placeholder="Final Amount"
                                    readonly
                                  />
                                </div>
                                <div class="mb-1">
                                  <div class="row">
                                    <div class="col-12">
                                      <!-- <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Coupon Code</label
                                      >
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="coupon_code"
                                        v-model="form.coupon_code"
                                        placeholder="Coupon Code"
                                      /> -->
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Discount Message</label
                                      >
                                      <textarea
                                        :readonly="!bookingResult.coupon_amount"
                                        type="text"
                                        class="form-control"
                                        name="coupon_code"
                                        v-model="form.discount_message"
                                        placeholder="Discount Message"
                                      ></textarea>
                                    </div>
                                    <!-- <div class="col-3">
                                      <button
                                        type="button"
                                        class="btn btn-primary applybtn"
                                        @click="applyCoupanCode()"
                                        :disabled="
                                          form.check_in_date == '' &&
                                          form.check_out_date == ''
                                        "
                                      >
                                        Apply
                                      </button>
                                    </div> -->
                                  </div>
                                </div>
                                <div class="mb-1">
                                  <label
                                    class="form-label"
                                    for="basic-addon-name"
                                    >Payment Mode</label
                                  >
                                  <Select2
                                    v-model="form.payment_mode_id"
                                    :options="$store.state.paymentModeData"
                                    :settings="{
                                      placeholder: 'Choose Payment Mode',
                                    }"
                                  />
                                  <div
                                    class="invalid-feedback fw-bold fst-italic"
                                    :style="{ display: 'none' }"
                                  >
                                    Please Choose Payment Mode.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="p-1 mt-2 sectionBlock"
                            v-if="active_persons"
                          >
                            <div class="row">
                              <div class="col-12">
                                <div
                                  class="invoice-padding invoice-product-details"
                                >
                                  <div class="source-item">
                                    <div data-repeater-list="group-a">
                                      <div
                                        v-for="(item, index) in form.persons"
                                        :key="index"
                                      >
                                        <div
                                          :class="
                                            index === 0
                                              ? `border-bottom sectionBlock mb-1 pt-1 pb-1`
                                              : index === 1
                                              ? `selectioBoxOther pt-1`
                                              : ``
                                          "
                                          class="d-flex row"
                                        >
                                          <h4 v-show="index === 0">
                                            Primary Member Detail
                                          </h4>
                                          <h4 v-show="index === 1">
                                            Other Member Detail
                                          </h4>
                                          <div class="col-md-2 col-sm-6">
                                            <div class="mb-1">
                                              <label
                                                class="form-label"
                                                for="basic-addon-name"
                                                >First Name<span>*</span></label
                                              >
                                              <input
                                                type="text"
                                                class="form-control"
                                                name="first_name"
                                                placeholder="First Name"
                                                v-model="
                                                  form.persons[index].first_name
                                                "
                                              />
                                              <div
                                                class="fw-bold fst-italic error"
                                                v-show="firstError[index]"
                                              >
                                                {{ firstError[index] }}
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-2 col-sm-6">
                                            <div class="mb-1">
                                              <label
                                                class="form-label"
                                                for="basic-addon-name"
                                                >Middle Name</label
                                              >
                                              <input
                                                type="text"
                                                class="form-control"
                                                name="middle_name"
                                                placeholder="Middle Name"
                                                v-model="
                                                  form.persons[index]
                                                    .middle_name
                                                "
                                              />
                                              <!-- <div class="fw-bold fst-italic error" > Please enter Middle name. </div> -->
                                            </div>
                                          </div>
                                          <div class="col-md-2 col-sm-6">
                                            <div class="mb-1">
                                              <label
                                                class="form-label"
                                                for="basic-addon-name"
                                                >Last Name<span>*</span></label
                                              >
                                              <input
                                                type="text"
                                                class="form-control"
                                                name="last_name"
                                                placeholder="Last Name"
                                                v-model="
                                                  form.persons[index].last_name
                                                "
                                              />
                                              <div
                                                class="fw-bold fst-italic error"
                                                v-show="lastError[index]"
                                              >
                                                {{ lastError[index] }}
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-2 col-sm-6">
                                            <div class="mb-1">
                                              <label
                                                class="form-label"
                                                for="basic-addon-name"
                                                >Mobile<span>*</span></label
                                              >
                                              <input
                                                type="number"
                                                class="form-control"
                                                name="mobile"
                                                v-model="
                                                  form.persons[index].mobile
                                                "
                                                :id="'mobile' + index"
                                                placeholder="Mobile"
                                              />
                                              <div
                                                class="error fw-bold fst-italic"
                                                v-show="mobileError[index]"
                                              >
                                                {{ mobileError[index] }}
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-2 col-sm-6">
                                            <div class="mb-1">
                                              <label
                                                class="form-label"
                                                for="basic-addon-name"
                                                >Id Proof</label
                                              >
                                              <input
                                                type="file"
                                                class="form-control"
                                                name="id_proof"
                                                ref="proofFile"
                                                placeholder="Id Proof"
                                                :id="index"
                                                @change="
                                                  uploadProofImage(
                                                    index,
                                                    $event
                                                  )
                                                "
                                              />
                                            </div>
                                          </div>
                                          <div
                                            class="col-md-1 col-sm-6 img-view"
                                            :class="
                                              form.persons[index].id_tmp_proof
                                                ? ``
                                                : `border`
                                            "
                                          >
                                            <div id="user-avatar-section">
                                              <div
                                                id="d-flex justify-content-start"
                                              >
                                                <img
                                                  v-if="
                                                    form.persons[index]
                                                      .id_tmp_proof
                                                  "
                                                  :src="
                                                    form.persons[index]
                                                      .id_tmp_proof
                                                  "
                                                  class="img-fluid rounded"
                                                  height="70"
                                                  width="70"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="col-md-1 col-sm-6 trash-view"
                                          >
                                            <div
                                              class="d-flex"
                                              v-if="index !== 0"
                                            >
                                              <vue-feather
                                                type="trash-2"
                                                size="20"
                                                @click="
                                                  removeContent(
                                                    index,
                                                    form.persons[index]
                                                      .detail_id
                                                  )
                                                "
                                              ></vue-feather
                                              >&nbsp;&nbsp;
                                            </div>
                                            <!-- <span
                                              class="badge badge-light-primary rounded-pill float-end"
                                              >{{ index + 1 }}</span
                                            > -->
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="row mt-1"
                                      v-show="
                                        form.persons.length <
                                        bookingResult.person
                                      "
                                    >
                                      <div class="col-12">
                                        <button
                                          type="button"
                                          class="btn btn-primary btn-sm btn-add-new float-end"
                                          @click="addToContent()"
                                          data-repeater-create
                                        >
                                          <vue-feather
                                            type="plus"
                                            size="15"
                                          ></vue-feather>
                                          <span class="align-middle"
                                            >Add Person</span
                                          >
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2" v-if="form.check_in_date">
                            <div class="col-12 text-center">
                              <button
                                type="submit"
                                class="btn btn-primary me-1 data-submit"
                                v-show="
                                  bookingResult.person > 0 &&
                                  !payurl &&
                                  form.payment_mode_id
                                "
                                :disabled="disabled"
                                @click="bookVilla()"
                              >
                                Book Now
                              </button>
                              <button
                                type="reset"
                                :disabled="disabled"
                                @click="resetForm()"
                                class="btn btn-outline-secondary"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Form from "vform";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import Select2 from "vue3-select2-component";

export default {
  name: "BookingAdd",
  components: {
    Datepicker,
    Select2,
  },
  data() {
    return {
      villaList: [],
      form: new Form({
        id: [],
        rangeDate: "",
        check_in_date: "",
        check_out_date: "",
        days: 0,
        coupon_amount: "",
        coupon_code: "",
        discount_message: "",
        payment_mode_id: "",
        qty: 0,
        persons: [
          {
            detail_id: "",
            user_id: "",
            barcode_id: "",
            first_name: "",
            middle_name: "",
            last_name: "",
            mobile: "",
            id_proof: "",
            id_tmp_proof: "",
            is_primary: true,
            user_type: "Guest",
          },
        ],
      }),
      bookingResult: {
        villa_price: 0,
        total: 0,
        extra_person_charge: 0,
        festival_charge: 0,
        gst_tax: 0,
        gst_tax_percent: 0,
        person: 0,
        coupon_amount: 0,
      },
      villa_booking: [],
      villa_type: {
        platinum: {
          qty: 0,
          person: 0,
        },
        diamond: {
          qty: 0,
          person: 0,
        },
        cottages: {
          qty: 0,
          person: 0,
        },
      },
      reserve_error: {
        platinum: {
          error: null,
        },
        diamond: {
          error: null,
        },
        cottages: {
          error: null,
        },
      },
      clickVillaId: [],
      firstError: [],
      lastError: [],
      mobileError: [],
      totalAdultError: "none",
      checkInDateError: "none",
      discount_message_error: false,
      errors: {},
      token: localStorage.getItem("auth_token"),
      loader: false,
      disabled: false,
      payurl: null,
    };
  },
  beforeCreate() {
    var element = document.querySelector(".modal-backdrop");
    if (element) {
      element.remove();
    }

    var element2 = document.querySelector(".modal-open");
    if (element2) {
      element2.style.overflow = "unset";
    }
  },
  async mounted() {
    this.$store.dispatch("getPaymentModeList");
  },
  methods: {
    dateResult() {
      const date = this.form.rangeDate;
      if (date.length > 0 && date.length != undefined) {
        date.map(() => {
          const start_date = moment(date[0]).format("YYYY-MM-DD");
          const end_date = moment(date[1]).format("YYYY-MM-DD");
          this.form.check_in_date = start_date;
          this.form.check_out_date = end_date;

          const dateA = moment(end_date);
          const dateB = moment(start_date);
          this.form.days = dateA.diff(dateB, "days") + 1;
        });
      } else {
        if (typeof date != undefined) {
          this.form.check_in_date = moment(date).format("YYYY-MM-DD");
          this.form.check_out_date = moment(date).format("YYYY-MM-DD");
          this.form.days = 1;
        }
      }
    },
    dateChange() {
      this.dateResult();
      if (this.form.rangeDate) {
        axios
          .post(
            process.env.VUE_APP_API_CUS_URL + "/search-villa",
            {
              check_in_date: this.form.check_in_date,
              check_out_date: this.form.check_out_date,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
              },
            }
          )
          .then((response) => {
            this.bookingResult = {
              villa_price: 0,
              total: 0,
              extra_person_charge: 0,
              festival_charge: 0,
              gst_tax: 0,
              gst_tax_percent: 0,
              person: 0,
              coupon_amount: 0,
            };
            this.villaList = [];
            this.villaList = response.data.data;
            if (this.villaList) {
              this.villa_booking = [];
              for (var i = 0; i <= this.villaList.length; i++) {
                if (this.villaList[i] && this.villaList[i].villa_name) {
                  var vType = this.villaList[i].villa_name.split(" ");
                  if (vType) {
                    this.villaList[i].pack_type = vType[0].toLowerCase();
                    this.villa_type[vType[0].toLowerCase()] = {
                      qty: 0,
                      person: 0,
                    };
                  }

                  this.villa_booking.push({
                    villa_type_id: this.villaList[i].villaTypeId,
                    quantity: this.villa_type[vType[0].toLowerCase()].qty,
                    person: this.villa_type[vType[0].toLowerCase()].person,
                    villa_name: this.villaList[i].villa_name,
                    check_in_date: this.form.check_in_date,
                    check_out_date: this.form.check_out_date,
                  });
                }
              }
            }
          })
          .catch((e) => {
            console.log("e: ", e);
          });
      }
    },
    addToContent() {
      this.form.persons.push({
        detail_id: this.form.detail_id,
        user_id: this.form.user_id,
        barcode_id: this.form.barcode_id,
        first_name: this.form.first_name,
        middle_name: this.form.middle_name,
        last_name: this.form.last_name,
        mobile: this.form.mobile,
        id_proof: this.form.id_proof,
        id_tmp_proof: this.form.id_tmp_proof,
        is_primary: false,
        user_type: "Guest",
      });
      this.form.persons.detail_id = null;
      this.form.persons.user_id = null;
      this.form.persons.barcode_id = null;
      this.form.persons.first_name = "";
      this.form.persons.middle_name = "";
      this.form.persons.last_name = "";
      this.form.persons.mobile = "";
      this.form.persons.id_proof = "";
      this.form.persons.id_tmp_proof = "";
      this.form.persons.is_primary = "";
      this.form.persons.user_type = "Guest";
    },
    removeContent(idx, detailId = null) {
      if (detailId) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to Delete this User!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ms-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            axios
              .post(
                process.env.VUE_APP_API_URL + "/booking/villa/delete-user",
                {
                  detail_id: detailId,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${this.token}`,
                  },
                }
              )
              .then((response) => {
                if (response.data.status) {
                  this.form.persons.splice(idx, 1);
                  this.disabled = false;
                } else {
                  this.$toast.error(response.data.error?.[0]);
                }
              })
              .catch((error) => {
                this.$toast.error(error);
              });
          }
        });
      } else {
        this.form.persons.splice(idx, 1);
        this.disabled = false;
      }
    },
    uploadProofImage(index, e) {
      let imgProof = e.target.files[0];
      if (imgProof.size / 1024 >= 7000) {
        e.target.value = null;
        this.$toast.error("File too big, please select a file less than 400kb");
        return false;
      }
      this.createBase64Image(imgProof, index);
    },
    createBase64Image(fileObject, index) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // const newImageEncode = e.target.result.split("base64,");
        // this.form.persons[index].id_tmp_proof = e.target.result;
        // this.form.persons[index].id_proof = newImageEncode[1];
        this.form.persons[index].id_tmp_proof = e.target.result;
        this.form.persons[index].id_proof = fileObject;
      };
      reader.readAsDataURL(fileObject);
    },
    villaClick(villa_id) {
      var chek = this.clickVillaId.find((c) => c == villa_id);
      if (chek) {
        console.log(chek);
      } else {
        this.clickVillaId.push(villa_id);
      }
    },
    onChangeVillaQty(villa_id, qty, person) {
      //this.form.persons = [];
      // this.form.persons.push({
      //   detail_id: "",
      //   user_id: "",
      //   barcode_id: "",
      //   first_name: "",
      //   middle_name: "",
      //   last_name: "",
      //   mobile: "",
      //   id_proof: "",
      //   is_primary: true,
      //   user_type: "Guest",
      // });

      for (let [inQ, villaQ] of this.villa_booking.entries()) {
        if (this.villa_booking[inQ].villa_type_id == villa_id) {
          if (this.form.id.includes(villaQ.villa_type_id)) {
            console.log("villaQ: ", villaQ.villa_type_id);
          } else {
            this.form.id.push(villaQ.villa_type_id);
          }
          this.villa_booking[inQ] = {
            villa_type_id: villaQ.villa_type_id,
            quantity: parseInt(qty.split("_") ? qty.split("_")[1] : 0),
            person: person
              ? parseInt(person.split("_") ? person.split("_")[1] : 0)
              : 0,
            villa_name: villaQ.villa_name,
            check_in_date: this.form.check_in_date,
            check_out_date: this.form.check_out_date,
          };
          this.villaBookingTotal();
        }
      }
    },
    async villaBookingTotal() {
      axios
        .post(
          process.env.VUE_APP_API_CUS_URL + "/villa-booking-total",
          {
            villa_booking: this.villa_booking,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.data) {
            this.bookingResult.villa_price = response.data.data.total;
            this.bookingResult.gst_tax_percent = response.data.data.gst_tax
              ? response.data.data.gst_tax
              : 0;
            this.bookingResult.extra_person_charge =
              response.data.data.extra_person_charge;
            this.bookingResult.festival_charge =
              response.data.data.festival_charge;
            this.bookingResult.person = response.data.data.person;
          }
        })
        .catch((e) => {
          console.log("e: ", e);
        });
    },
    async applyCoupanCode() {
      this.errors = {};

      await axios
        .post(
          process.env.VUE_APP_API_URL + "/coupon-code-admin",
          {
            check_in_date: this.form.check_in_date,
            check_out_date: this.form.check_out_date,
            coupon_code: this.form.coupon_code,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.code == 200) {
            var res = response.data.data;
            var total =
              this.bookingResult.villa_price +
              this.bookingResult.festival_charge +
              this.bookingResult.extra_person_charge;
            if (total > parseFloat(res.coupon_amount)) {
              this.bookingResult.coupon_amount = parseFloat(res.coupon_amount);
              this.$toast.success("Coupon Applied!");
            } else {
              this.$toast.warning("Coupon Invalid!");
            }
          } else if (response.data.code == 400) {
            this.$toast.warning(response.data.error?.[0]);
          } else if (
            response.data.code == 422 &&
            response.data.status == false
          ) {
            this.$toast.warning(response.data.message);
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    resetForm() {
      this.form.rangeDate = "";
      this.form.check_in_date = "";
      this.form.check_out_date = "";
      this.form.payment_mode_id = "";
      this.villaList = [];
      this.villa_booking = [];
      this.clickVillaId = [];
      this.form.persons = [];
      this.form.persons.push({
        detail_id: "",
        user_id: "",
        barcode_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        mobile: "",
        id_proof: "",
        id_tmp_proof: "",
        is_primary: true,
        user_type: "Guest",
      });
      this.bookingResult = {
        villa_price: 0,
        total: 0,
        extra_person_charge: 0,
        festival_charge: 0,
        gst_tax: 0,
        gst_tax_percent: 0,
        person: 0,
        coupon_amount: 0,
      };
      this.villa_type = {
        platinum: {
          qty: 0,
          person: 0,
        },
        diamond: {
          qty: 0,
          person: 0,
        },
        cottages: {
          qty: 0,
          person: 0,
        },
      };
      this.reserve_error = {
        platinum: {
          error: null,
        },
        diamond: {
          error: null,
        },
        cottages: {
          error: null,
        },
      };
    },
    async bookVilla() {
      this.dateResult();

      if (this.form.persons.length > this.bookingResult.person) {
        this.$toast.error("Please remove extra members.");
        return false;
      }

      var total_villa_qty = 0;

      var reserve_error_count = 0;

      this.villa_booking.forEach((element) => {
        total_villa_qty += element.quantity;
        reserve_error_count;
        var vType = element.villa_name.split(" ");
        if (element.quantity > 0 && element.person == 0) {
          this.reserve_error[vType[0].toLowerCase()] = {
            error: "Villa quanitity and person are must be selected first!*",
          };
          reserve_error_count++;
        } else {
          this.reserve_error[vType[0].toLowerCase()] = {
            error: "",
          };
        }
      });

      this.firstError = [];
      this.lastError = [];
      this.mobileError = [];
      for (var i = 0; i < this.form.persons.length; i++) {
        if (
          this.form.persons[i].first_name == undefined ||
          this.form.persons[i].first_name == null ||
          this.form.persons[i].first_name == ""
        ) {
          this.firstError[i] = "Please enter first name.";
          return;
        }
        if (
          this.form.persons[i].last_name == undefined ||
          this.form.persons[i].last_name == null ||
          this.form.persons[i].last_name == ""
        ) {
          this.lastError[i] = "Please enter last name.";
          return;
        }

        if (
          this.form.persons[i].mobile == undefined ||
          this.form.persons[i].mobile == null ||
          this.form.persons[i].mobile == ""
        ) {
          this.mobileError[i] = "Please enter mobile no.";
          return;
        }

        if (this.form.persons[i].mobile.toString().length != 10) {
          this.mobileError[i] = "Please enter valid mobile no.";
          return;
        }
      }

      var mobiles = this.form.persons.map(({ mobile }) => mobile);

      if (
        mobiles.filter((item, index) => mobiles.indexOf(item) !== index)
          .length > 0
      ) {
        this.$toast.error("Please enter different mobile no. in each person.");
        return false;
      }

      if (this.form.persons.length != this.bookingResult.person) {
        this.$toast.error("Please add all members details.");
        return false;
      }

      console.log(total_villa_qty);

      if (this.form.persons.length < total_villa_qty) {
        this.$toast.error("Minimum " + total_villa_qty + " villa persons required.");
        return false;
      }

      this.disabled = true;

      var apiUrl = process.env.VUE_APP_API_CUS_URL + "/villa-book";

      var newPersons = [];

      this.form.persons.forEach((element, index) => {
        delete element.id_tmp_proof;
        element.is_primary = element.is_primary == true ? 1 : 0;
        newPersons[index] = element;
      });

      await axios
        .post(
          apiUrl,
          {
            check_in_date: this.form.check_in_date,
            check_out_date: this.form.check_out_date,
            total_child: 0,
            coupon_code: this.form.coupon_code,
            discount_message: this.form.discount_message,
            coupon_amount: this.showCalc.coupon_amount,
            payment_mode_id: this.form.payment_mode_id,
            total: this.showCalc.total,
            extra_person_charge: this.showCalc.extra_person_charge,
            festival_charge: this.showCalc.festival_charge,
            gst_tax: this.showCalc.gst_tax_percent,
            gst_amount: 0,
            person: this.bookingResult.person,
            sub_total: this.showCalc.sub_total,
            final_total: this.showCalc.final_total,
            villa_booking: this.villa_booking,
            persons: newPersons,
            type: "",
            id: null,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.payurl = null;

            if (this.form.payment_mode_id == 1) {
              this.$toast.success(response.data?.message);
              this.$router.push({ name: "booking" });
            } else {
              this.getPayLink(
                response.data.data.booking_id,
                response.data.data.user_id
              );
            }
          } else {
            this.$toast.error(response.data?.message);
          }

          setTimeout(() => {
            this.disabled = false;
          }, 500);
        });
    },
    async getPayLink(booking_id, user_id) {
      this.payurl = null;

      const formdata = new FormData();
      formdata.append("amount", this.showCalc.final_total);
      formdata.append("user_id", user_id);
      formdata.append("device", "ad");
      formdata.append("mobileNumber", this.form.persons[0].mobile);
      formdata.append("redirectMode", "REDIRECT");
      formdata.append("booking_id", booking_id);
      formdata.append("redirectUrl", process.env.VUE_APP_URL + "/booking");

      await axios
        .post(process.env.VUE_APP_API_CUS_URL + "/pay-online", formdata, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        // await axios.post("https://crm-server.riocolina.com/api/customer/pay-online", formdata)
        .then((response) => {
          if (response.data.links) {
            this.payurl = response.data.links.pay_link_web;
            window.location.href = response.data.links.pay_link_web;
          }
        });
    },
  },
  computed: {
    active_persons: function () {
      var active_status = false;
      if (this.form.check_in_date && this.showCalc.total) {
        if (this.form.payment_mode_id) {
          active_status = true;
        }
      }
      return active_status;
    },
    active_booking: function () {
      var active_status = false;
      if (this.active_persons) {
        if (this.form.persons.length == this.bookingResult.person) {
          active_status = true;
        }
      }

      return active_status;
    },
    showCalc: function () {
      var var_villa_price = 0;
      if (this.bookingResult.villa_price) {
        var_villa_price = parseFloat(this.bookingResult.villa_price);
      }

      var var_extra_person_charge = 0;
      if (this.bookingResult.extra_person_charge) {
        var_extra_person_charge = parseFloat(
          this.bookingResult.extra_person_charge
        );
      }

      var var_festival_charge = 0;
      if (this.bookingResult.festival_charge) {
        var_festival_charge = parseFloat(this.bookingResult.festival_charge);
      }

      var var_coupon_amount = 0;
      if (this.bookingResult.coupon_amount) {
        var_coupon_amount = parseFloat(this.bookingResult.coupon_amount);
      }

      var var_gst_tax_percent = 0;
      if (this.bookingResult.gst_tax_percent) {
        var_gst_tax_percent = parseFloat(this.bookingResult.gst_tax_percent);
      }

      var var_gst_tax_amt = 0;
      if (var_gst_tax_percent) {
        var_gst_tax_amt =
          ((var_villa_price +
            var_extra_person_charge +
            var_festival_charge -
            var_coupon_amount) *
            var_gst_tax_percent) /
          100;
      }

      var var_total = 0;
      if (var_villa_price) {
        var_total = var_villa_price;
      }

      var var_sub_total =
        var_villa_price +
        var_extra_person_charge +
        var_festival_charge -
        var_coupon_amount;
      var var_final_total = var_sub_total + var_gst_tax_amt;

      return {
        villa_price: var_villa_price,
        total: var_total,
        festival_charge: var_festival_charge,
        extra_person_charge: var_extra_person_charge,
        sub_total: var_sub_total,
        coupon_amount: var_coupon_amount,
        gst_tax: var_villa_price - var_villa_price * (100 / (100 + 18)),
        gst_tax_percent: var_gst_tax_percent,
        final_total: var_final_total,
      };
    },
  },
};
</script>
<style scoped>
.card-datatable {
  overflow-x: auto;
}

.villa_card_wrapper {
  width: 100%;
  display: flex;
}
.noslotavailable {
  padding: 15px;
  display: flex;
  justify-content: center;
  width: 100%;
  opacity: 0.5;
}

.product-box {
  border: 1px solid #ebe9f1;
  border-radius: 0.357rem;
}

.btn-add-new span {
  margin-top: 5px;
}

.btn-add-new {
  display: flex;
}

.vue-feather--plus {
  margin: 2px 3px 0px 0px;
}

.img-view {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.trash-view {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.trash-view i {
  color: #fff;
  transition: all 0.2s ease;
  background-image: linear-gradient(47deg, #ea5455, #f08182);
  background-repeat: repeat;
  padding: 0.715rem 0.736rem;
  border-radius: 0.358rem;
  cursor: pointer;
}

.trash-view .disable {
  background-image: linear-gradient(47deg, #302a2a, #adabab) !important;
}

.trash-view i:hover {
  transform: translateY(-2px);
}

.sectionBlock {
  box-shadow: 6px 6px 25px -8px #9e9e9e;
}

.error {
  color: #ea5455;
  font-size: 0.857rem;
}

.form-check {
  padding-left: 0.785rem !important;
}

.status-badge {
  position: relative;
  cursor: pointer;
}

.statusDiv {
  position: absolute;
  box-shadow: 6px 6px 25px -8px #9e9e9e;
  padding: 5px;
  border-radius: 10px;
  z-index: 9999;
  background-color: #fff;
}

.statusDiv ul,
.mobileDiv ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0 !important;
}

.statusDiv ul li,
.mobileDiv ul li {
  padding: 5px;
  cursor: pointer;
}

.statusDiv ul li:hover,
.mobileDiv ul li:hover {
  background-color: #e3e0ff;
  font-weight: bold;
  border-radius: 5px;
}

.statusZindex {
  z-index: -1;
}

.selectioBoxOther {
  box-shadow: 2px -12px 16px -8px #dbdbdb;
}

.mobileSearchDiv {
  position: relative;
}

.mobileDiv {
  position: absolute;
  padding: 5px 10px;
  border-radius: 10px !important;
  z-index: 9999;
  top: 50px;
  height: auto;
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  transition: all 1s;
  background-color: #fff;
  box-shadow: 1px 12px 25px 4px #9e9e9e !important;
}

.search-mobile {
  border-right: 1px solid #d8d6de !important;
  border-top-right-radius: 0.357rem !important;
  border-bottom-right-radius: 0.357rem !important;
}

.search-mobile:focus {
  border-color: #7367f0 !important;
}

.wrapper-option {
  display: flex;
}

.applybtn {
  margin-top: 23px;
  width: 100%;
}

.bk_title_info_info_main_row {
  margin: 0px -10px;
}

.bk_title_info_info_main_row {
  display: flex;
  flex-wrap: wrap;
}

.bk_title_info_info_main_row .bk_title_info_info {
  width: 33.33%;
  height: 100%;
  padding: 10px;
}

.bk_title_info_info_inr {
  background: #fff;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 14px #dceae8;
}

.bk_title_info {
  display: flex;
  padding: 12px;
  flex-direction: column;
}

.bk_title_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 7px;
}

.bk_content {
  padding-top: 10px;
}

.vila_title {
  text-transform: capitalize;
  margin-bottom: 10px;
  text-align: left;
  padding: 0 !important;
}

.vila_title_info,
.main_qun ul {
  display: flex;
  padding: 2px 0px;
  align-items: center;
}

.vila_title_info {
  font-size: 12px;
  color: #42aea3;
}

.vila_title_info svg {
  width: 25px;
  height: 12px;
}

.vialla_qty_main_inner {
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 0px 14px #dceae8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 15px 15px;
  display: flex;
}

.vialla_qty_main {
  flex: 1;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vialla_qty_main:nth-child(1) {
  margin-right: 5px;
}

.vialla_qty_main:nth-child(2) {
  margin-left: 5px;
}

.main_class_inner_sd.select_image {
  width: 100%;
  position: relative;
}

.main_class_inner_sd.select_image svg {
  left: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.vialla_qty_main select {
  width: 100%;
  height: 32px;
  padding-left: 20px;
}

p.reverse_error {
  padding: 10px;
  color: red;
}

.overflow_auto {
  overflow: auto !important;
}
.form-label span {
  color: red;
  margin-left: 2px;
  font-size: 15px;
  font-weight: bold;
}
</style>
